@import "../../../partials/mixins";

.Searcher {

  width: 100%;

  .title {
    @include title;
  }

  .main-searcher{

    .searcher{
      border-bottom: 1px solid red;
      display: flex;
      justify-content: space-evenly;
      padding: 20px;
      // border-radius: 10px;
  
      .containter-input{
          width: 90%;
      }
    }

    // .fetch_results{
    //   padding: 20px;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: space-evenly;

    //   .fetch_oneFetch{
    //     .fetch_oneFetch_text{
    //       text-align: center;
    //       font-size: 25px;
    //       padding-bottom: 10px;
    //     }


    //     .array1{
    //       display: flex;
    //       justify-content: space-between;
    //       border-bottom: 1px solid black;
    //       padding: 0 10px 10px 10px;
    //       margin-bottom: 10px;
  
    //       // .array2{
  
    //       // }
    //     }
  
    //     p{
    //       font-size: 20px;
    //     }
    //   }

      
    // }
  }
}
