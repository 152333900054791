@import "../../../../partials/mixins";
@import "../../../../partials/variables";

$word_set-width: 102px;
$date_create-width: 120px;
$actual_status-width: 120px;

$onePiece: calc((100% - $word_set-width - $date_create-width - $actual_status-width - 50px) / 3);

.NewRespondent_Result{
    .titles{
        @include titles_line;

        p{
            text-align: center;
        }

        .name_respondent,
        .tag{
            p{
                text-align: left;
                padding-left: 10px;
            }
        }
        
        .company{
            text-align: left;
        }
        .company{
            padding-left: 5px;
        }
    }

    .name_respondent{
        width: $onePiece;
    }
    .tag{
        width: $onePiece;

        .tag_box{
            @include tag_box;
        }
    }
    .company{
        width: $onePiece;
    }
    .open_row{
        gap: 10px;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;

        *{
            cursor: pointer;
        }
        
        .company{
            flex: 1;
        }
        .word_set {
            width: $word_set-width;
        }
        .date_create {
            width: $date_create-width;
        }
        .actual_status{
            width: $actual_status-width;
        }
    }
    

    .oneColorSet_info{
        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        gap: 10px;
        border-bottom: 1px solid rgb(204, 204, 204);
        

        .name_respondent{
            @include name_email;

            .email_btn{
                text-align: left;
            }
        }
        .open_row{
            display: flex;
            justify-content: flex-end;

            &:hover {
                background-color: $oneMapSelect-hover-color;
                border-radius: 10px;
            }

            .company{
                display: flex;
                align-items: center;
                padding-left: 5px;
            }
            .word_set,
            .date_create,
            .actual_status{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        
    }
}