// @import "../../../../partials/variables";
@import "../../../../partials/mixins";

// $private_title-width: 100px;
// $word_set-width: 120px;
// $date_create-width: 120px;
// $complete_notComplete-width: 102px;

// $page-width: calc(100% - $navDataUser-width + 8px);

.Tags_response_listFromLast{
    .titles{
        @include titles_line;

        p{
            text-align: center;
        }

        .Tag_name{
            p{
                text-align: left;
                padding-left: 10px;
            }
        }
        .company{
            text-align: left;
        }
    }

    .Tag_name{
        flex: 1;
    }
    .company{
        flex: 1;
        // border-right: 1px solid $dmaps-darkBlue;
        text-align: left;
        padding: 0 5px;
    }
    .word_set{
        width: 120px;
        // border-right: 1px solid $dmaps-darkBlue;
        // border-left: 1px solid $dmaps-darkBlue;
    }
    .date_create{
        width: 130px;
        // border-right: 1px solid $dmaps-darkBlue;
        // border-left: 1px solid $dmaps-darkBlue;
    }
    .private_title{
        width: 100px;
        // border-left: 1px solid $dmaps-darkBlue;
        
    }
    .complete_notComplete{
        width: 102px;
        // border-right: 1px solid $dmaps-darkBlue;
        // border-left: 1px solid $dmaps-darkBlue;
    }

    .tag_row{
        flex-wrap: wrap;
        gap: 10px;
        display: flex;
        padding: 5px;
        border-bottom: 1px solid rgb(204, 204, 204);
        

        &:hover {
            background-color: $oneMapSelect-hover-color;
            border-radius: 10px;
        }

        cursor: pointer;
        * {
            cursor: pointer;
        }

        .private_title{
            display: flex;
        }

        .Tag_name{
            .tag_box{
                @include tag_box;

                &:hover {
                    transform: none;
                }

                .job_position{
                    min-height: 30.91px;
                }
            }
        }
        

        .company,
        .word_set,
        .date_create,
        .complete_notComplete {
            @include flex-center;
        }
        .company{
            justify-content: flex-start;
        }
    }
}