.meaningText_Graf8 {
    display: flex;
    background-color: rgb(233, 233, 233);
    padding: 10px;
    gap: 10px;
    border-radius: 20px;

    .meaningText_Graf8_one{
      flex: 1;
      background-color: white;
      padding: 6px;
      border-radius: 10px;

      .graf8_title{
        color: rgb(84, 84, 194);
        font-size: 20px;
        padding-bottom: 5px;
      }
    }
  }