@import "../../partials/variables";
@import "../../partials/mixins";

.MeaningOfWords {
  // @include page;
  // flex-direction: row;
  // display: flex;
        // .checkbox {
      //   display: flex;
      //   flex-direction: column;
      //   margin-bottom: 30px;

      //   label {
      //     white-space: nowrap;
      //   }
      // }

  .searcher-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .Searcher{
      width: 100%;
    }

    .frist_row {
      display: flex;
      align-items: flex-end;
      width: 100%;

      .mainWord {
        display: flex;
        width: 100%;
      }
    }

    .meaning_box {
      display: flex;
      align-items: flex-end;
      width: 100%;

      // .checkbox {
      //   display: flex;
      //   flex-direction: column;
      //   margin-bottom: 30px;

      //   label {
      //     white-space: nowrap;
      //   }
      // }
      
    }

    .btn_box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-bottom: 2px solid blue;
    }
  }
  //   .SearcherResult {
  //   }
}
