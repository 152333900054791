@import "../../../partials/mixins";

.NewRespondent_oneRespondent {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); // For Safari
  z-index: 19;
  display: flex;
  justify-content: center;
  // align-items: center;
  border-radius: 20px;

  .NewRespondent_oneRespondent_main {
    padding: 0 2% 10px 2%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .title{
      @include title;
      h1{
        font-size: 20px;
      }
    }
    // h1 {
    //   text-align: center;
    //   font-size: 30px;
    //   padding: 12px;
    // }

    .Tag_Users {
      @include TagIntro;
      width: 100%;

      .tag_box{
        width: 100%;

        &:hover{
          transform: none;
        }

        .tagInfo_box_admin,
        .tagInfo_box_tag,
        .tagInfo_box_company,
        .tagInfo_box_dateCreate{
          cursor: default;

          *{
            cursor: default;
          }
        }
        .tagInfo_box_tag,
        .tagInfo_box_company{
          p{
            width: 100%;
          }
        }
        // .tagInfo_box_company{
        //   .company{
        //     width: 100%;
        //   }
        // }
        
        .newRespondent_delete{

          .Btn_Delete{
            .btnDelete{
              background-color: white;
              border-radius: 50%;
              padding: 10px 14px;
  
              svg{
                font-size: 30px;
              }
            }
          }    
        }
      }
    }

    .NewRespondent_oneRespondent_main_main {
      margin-top: 20px;
      width: 100%;
      background-color: rgb(255, 255, 255);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      border-radius: 30px;

      .NewRespondent_oneRespondent_data {
        width: 98%;
      }
  
      .date_attempts{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 10px;

        .date_create {
          border: 4px solid rgba(127, 255, 212, 0.2);
          width: fit-content;
          padding: 10px;
          font-size: 20px;
          background-color: rgba(127, 255, 212, 0.2);
          // margin-left: 50%;
          // transform: translateX(-50%);
          border-radius: 30px;
          text-align: center;
          height: 90px;
  
          .date_create_title {
            color: rgb(94, 94, 94);
          }
          .date_create_date {
            font-size: 24px;
          }
        }

        .name_email {
          p {
            text-align: center;
          }
          .name {
            font-size: 26px;
          }
          .email {
            font-size: 20px;
            color: rgb(94, 94, 94);
          }
        }

        .number_attempts{
          font-size: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 90px;
          padding: 0 20px;
          border: 4px solid rgba(127, 255, 212, 0.2);
          background-color: rgba(127, 255, 212, 0.2);
          border-radius: 30px;

          .InputLook_Text{
            .label_button{
              .btn_edit{
                color: black;
                border-color: black;
              }
            }

            .edit{
              input{
                font-size: 20px;
                border-color: black;
                color: black;
                text-align: center;
                min-width: 59.55px;
              }

              .original_text{
                height: 29.10px;
                
                &:hover {
                  border-color: black;
                }
              }
            }
          }
        }
      }
      

      .email_box {
        border: 4px solid rgba(127, 255, 212, 0.2);
        background-color: rgba(127, 255, 212, 0.2);
        border-radius: 30px;
        padding-bottom: 10px;
        margin-bottom: 5px;

        .Email_Icons_Info{
          justify-content: space-between;
          align-items: flex-start;
        }
      }

      .others_data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        flex-grow: 1;
        height: 100%;
        width: 100%;
        gap: 5px;

        
        .code_box{
            width: 90%;
            background-color: rgba(127, 255, 212, 0.2);
            border: 4px solid rgba(127, 255, 212, 0.2);
            padding: 20px;
            border-radius: 30px;
            text-align: center;
                        
            .code_title{
                font-size: 18px;
                color: rgb(94, 94, 94);
            }
            .tag_position{
              display: flex;
              justify-content: space-evenly;

              .position_text{
                font-size: 20px;
              }

            }
            .code_data{
                font-size: 24px;
            }
        }
      }
    }
  }
}

.TagBox_active{
  top: -2%;
  left: calc(-2% + 14px);
  width: calc(104% - 28px);
  background-color: white;


  // .BtnClose{
  //   top: 40px;
  // }

  .NewRespondent_oneRespondent_main{
    width: 100%;
  }
}