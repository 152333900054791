.Result_fetchData {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .one {
    border: 1px solid blue;
    padding: 15px;
    border-radius: 10px;

    // padding: 10px 0;
    .name {
      
      display: flex;

      .main_data{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .btnName {
          font-size: 30px;
          transition: all 0.2s linear;
          // padding: 0 20px;
          padding-bottom: 10px;
          width: 100%;
          height: 45px;
          
  
          &:hover{
              color: red;
              // padding-left: 10px;
          }
        }
        .email{
          width: 100%;
          text-align: center;
          font-size: 14px;
          padding-bottom: 5px;
        }
      }

      .buttons{
        display: flex;
        // background-color: rgba(0, 0, 0, 0.158);
        flex: 2;
        padding: 10px;
        border-radius: 20px;
        gap: 10px;
        margin-bottom: 15px;

        button{
          border: 2px solid rgba(0, 0, 255, 0.534);
          padding: 7px 12px;
          font-size: 20px;
          border-radius: 20px;
          background-color: aliceblue;
          flex: 1;
        }
      }
    //   .btn_showGraf{

    //   }

    }
    .details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .row {
        display: flex;
        padding-left: 10%;
        border: 1px solid blue;
        padding: 5px;
        border-radius: 5px;

        .item {
          display: flex;
          flex: 1;
          // justify-content: flex-start;
          padding: 10px;

          .title_item {
            // display: flex;
            // justify-content: flex-start;
            width: min-content;
            padding-right: 10px;
            color: rgb(156, 156, 156);
            // font-size: 1px;
            // margin-top: 3px;
          }

          p {
            white-space: nowrap;
            // text-align: left;
            width: 100%;
          }
        }
      }
    }
  }
}
