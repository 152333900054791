.CheckBox_onlyShow {
    
    width: 100%;
    // background-color: white;
    
    border-radius: 10px;
    // box-shadow: 0 0 10px white;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 18px;
      flex-direction: column;
      // padding: 10px 20px;
    }
  
    input[type="checkbox"] {
      display: none; /* Skryje původní checkbox */
    }
  
    .checkmark {
      width: 30px;
      height: 30px;
      background-color: #ccc;
      border-radius: 4px;
      position: relative;
      display: inline-block;
      transition: background-color 0.3s ease;
    }
  
    /* Styl zaškrtnutého checkboxu */
    input[type="checkbox"]:checked + .checkmark {
      background-color: #4caf50; /* Barva zaškrtnutí */
    }
  
    /* Symbol uvnitř checkboxu */
    .checkmark::after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 3px;
      width: 9px;
      height: 15px;
      border: solid white;
      border-width: 0 3px 2px 0;
      transform: rotate(45deg);
    }
  
    /* Zobrazení symbolu, když je checkbox zaškrtnutý */
    input[type="checkbox"]:checked + .checkmark::after {
      display: block;
    }
  }