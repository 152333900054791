.Email_Form{
    padding: 0 20px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        width: 45px;
        height: 45px;
    }
    .send {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .email_send {
            color: rgb(192, 192, 192);
            font-size: 40px; /* Přizpůsob velikosti */
        }

        .email_form {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); /* Perfektní vycentrování */
            color: white;
            text-shadow: 0 0 10px black;
            font-size: 36px;
            font-weight: bold;
        }
    }
    
   
    
    
    
    
    .noEmail_send{
        color: orange;
    }
}