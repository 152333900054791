@import "../../../../../partials/mixins";

.NewRespondent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .NewRespondent_title {
    @include title;
    // padding-top: 30px;
    // text-align: center;
  }
  .NewRespondent_main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .r1{
    justify-content: center;
    gap: 50px;
  }
}
