@import "../../../../partials/variables";

.BtnClose{
    position: absolute;
    width: 30px;
    height: 40px;
    right: 25px;
    top: 15px;
    transition: color 0.3s linear;
    color: $dmaps-orange;
    z-index: 9999;

    &:hover{
        color: rgb(211, 21, 21);
    }

    svg{
        width: 100%;
        height: 100%;
    }
}