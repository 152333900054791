.Loading_Dmaps_Loader{
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;

    .Loading_Dmaps_Loader_box{
        position: relative;
        .Loading_Dmaps{
            filter: drop-shadow(0 0 10px rgb(206, 206, 206));
        }
        .Loader{
            position: absolute;
            top: 30px;
            left: -12px;
            
        }
    }
}