@import "../../../../partials/variables";
@import "../../../../partials/mixins";

.NavFormData{
    background-color: rgb(128, 128, 128);
    width: 100%;
    // padding: 10.2px;
    height: $navFormData-height;
    @include flex-center;

    .nav-box{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 30px;
    }
}