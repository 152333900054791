.PopUpNewRespondent{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(233, 233, 233, 0.05);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); // For Safari
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    

    .PopUpNewRespondent_window{
        min-width: 600px;
        width: 40%;
        height: 90vh;
        border-radius: 10px;
        box-shadow: inset 3px 3px 8px rgba(0, 0, 0, 0.2);
        background-color: rgb(248, 248, 248);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        box-shadow: 0 0 10px black;
        z-index: 9999;

        h1{
            text-align: center;
            font-size: 20px;
        }  

        .PopUpNewRespondent_window_main{
            position: relative;
            height: 100%;
            overflow-y: auto;
            flex-grow: 1;
            padding: 10px;
        }
    }
}