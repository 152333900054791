@import "../../../../../partials/mixins";
@import "../../../../../partials/variables";

.Registration_window{
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: rgba(238, 238, 238, 0.5);
    backdrop-filter: blur(10px);
    
    -webkit-backdrop-filter: blur(10px); // For Safari

    display: flex;
    justify-content: center;
    align-items: center;
    
    @include logReg;
    
    

    form {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .form{
        max-height: 90vh;
        background-color: rgb(248, 248, 248);
        overflow-y: auto;
    }

    h1{
        font-size: 32px;
    }
    h2{
        text-align: center;
        margin-bottom: 10px;
    }

    .parent{
        border-top: 2px solid $border-color;
        box-shadow: 0 0 20px black;
        margin-top: 25px;
        margin-bottom: 10px;
    }

    .btn_colorSelect{
        padding-top: 20px;
        display: flex;
        justify-content: center;
        border-radius: 20px;

        button{
            background-color: white;
        }
    }

    // .error_box{
        
    // }
    

}