@import "../../../../../../partials/mixins";
@import "../../../../../../partials/variables";

.OneColorSet_GrafData {
  // border: 1px solid red;
  border-radius: 10px;
  padding: 10px;

  .row {
    @include row;
  }

  .colorSetInfo {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    h4{
      font-size: 35px;
      color: $menu-color;
    }
    h4,
    .email {
      text-align: center;
      // padding-bottom: 3px;
    }
    .email{
      padding-bottom: 10px;
    }

    .download_PDF{
      background-color: $menu-color;
      width: 100%;
      border-radius: 20px;
      position: relative;
      border: 3px solid $dmaps-orange;
      border-top: 4px solid $dmaps-orange;

      button {
        color: white;
        font-size: 18px;
        width: 100%;
        text-align: center;
        padding: 10px;
      }
      svg {
        font-size: 28px;
        position: absolute;
        right: 16px;
        top: 8px;
      }
    }

    .buttons_box {
      display: flex;
      background-color: $menu-color;
      flex: 2;
      padding: 10px;
      border-radius: 20px;
      gap: 10px;
      justify-content: space-evenly;
      gap: 20px;
      border: 3px solid $dmaps-orange;
      border-top: 4px solid $dmaps-orange;


      .btn_delete {
        display: flex;
        justify-content: center;
        align-items: center;

        .Btn_Delete{
          svg {
            color: white;
            font-size: 44px;
          }
        }
      }
    }
  }

  .userData{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s linear;

    .userData_box{
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .send_from_admin{
      background-color: $dmaps-lightBlue;
      border: 3px solid $dmaps-darkBlue;
      border-radius: 10px;
      padding: 10px 5px 5px 5px;
      margin-top: 5px;
      }

      .send_from_admin_title{
        text-align: center;
        padding-bottom: 5px;
        font-size: 20px;
      }

      .tag_job{
        display: flex;
        justify-content: center;
      }
    
  }
  .userData_show {
    max-height: 1000px; /* Nastavte dostatečně velkou hodnotu */
  }

  .influencing{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s linear;

    .influencing_box{
      padding: 10px;
      display: flex;
      justify-content: space-between;
      gap: 5px;
      border: 3px solid $dmaps-darkBlue;
      padding: 10px 10px;
      border-radius: 20px;
      background-color: $dmaps-lightBlue;

      p{
        border: 3px solid $dmaps-darkBlue;
        padding: 8px 16px;
        border-radius: 20px;
        color: white;
        font-weight: bold;
      }

      .influencing_true{
        background-color: $dmaps-orange;;
      }
      .influencing_false{
        background-color: green;
      }
    }
  }
  .influencing_show {
    max-height: 1000px; /* Nastavte dostatečně velkou hodnotu */
    
  }

  .data_forGraf {
    display: flex;
    gap: 20px;

    // .frequency_box {
    //   border: 1px solid black;
    //   border-radius: 10px;
    //   padding: 10px;
    //   height: fit-content;

    //   p {
    //     text-align: center;
    //     width: 40px;
    //   }

    //   .frequency_group {
    //     display: flex;
    //     justify-content: space-between;

    //     .text{
    //         padding-left: 10px;
    //         text-align: left;

    //     }
    //   }
    // }
  }

  .words_color_res {
    display: flex;
    gap: 10px;
    flex: 1;

    .OneColorSet_GrafData_title {
      font-size: 20px;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 2px solid $menu-color;
      text-align: center;
      color: $menu-color;
    }

    .wordsColorSetRes {
      padding: 10px;
      height: fit-content;
      box-shadow: 0 0 10px $menu-color;
      border-radius: 10px;
      flex: 1;
    }
  }

  .word_schema {
    font-size: 20px;
      margin: 20px;
      background-color: aliceblue;
      width: fit-content;
      padding: 10px 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px $menu-color;
      color: $menu-color;

  }
  .Ideály {
    background-color: rgba(0, 142, 251, 0.3);
  }
  .Motivace {
    background-color: rgba(0, 227, 150, 0.3);
  }
  .Neutralita {
    background-color: white;
  }
  .Stresory {
    background-color: rgba(0, 227, 150, 0.3);
  }
  .Destruktory {
    background-color: rgba(0, 143, 251, 0.3);
  }

  .wordSet_columns {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    // background-color: rgb(73, 73, 73);
    padding: 10px;
    gap: 10px;
    border-radius: 20px;

    .WordSet_columns_oneText {
      flex: 1;
      // background-color: white;
      padding: 6px;
      border-radius: 10px;
      box-shadow: 0 0 10px $menu-color;

      .oneText_title {
        color: $menu-color;
        font-size: 20px;
        padding-bottom: 5px;
      }
      .oneText_description {
        padding-bottom: 5px;
        // border-bottom: 1px solid black ;
        width: fit-content;
        margin-bottom: 5px;
      }
    }
  }
}
