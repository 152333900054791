@import "../../../../partials/variables";

.InputColor{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    padding: 10px;
    border: 2px solid $border-color;
    background-color: white;
    border-radius: 10px;

    h3{
        padding-left: 10px;
        padding-bottom: 10px;
    }

    .BtnClose{
        width: 20px;
        height: 20px;
        right: 10px;
    }

    .btnColorSelect{
        width: 100%;
        color: black;
        padding: 5px;
        border: 1px solid white;
        transition: all 0.2s linear;
        border-radius: 10px;

        &:hover{
            border-color: $border-color;
        }
    }
}