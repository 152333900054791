@import "../../../..//partials/variables";
@import "../../../..//partials/mixins";

.Companies_Result{
  .Companies_Result_box{
    .titles{
      @include titles_line;

      .admin_name,
      .coins,
      .admin_box{
        text-align: center;
      }
      .company {
        p{
          padding-left: 5px;
        }
      }
    }
  }

  // width
  .admin_name{
    width: 80px;
  }
  .company{
    flex: 1;
  }
  .coins{
    width: 90px;
  }
  .tags_number{
    width: 90px;
  }

  .company_row {
    cursor: pointer;
    display: flex;
    margin: 5px;
    padding: 5px;
    flex-wrap: wrap;
    gap: 10px;
    padding: 5px;
    border-bottom: 1px solid rgb(204, 204, 204);

    *{
        cursor: pointer;
    }

    &:hover {
        background-color: $oneMapSelect-hover-color;
        border-radius: 10px;
    }

  .admin_name {
    display: flex;
    justify-content: center;
    align-items: center;

    .admin_box {
      width: 40px;
      padding: 5px;
      border-radius: 50%;
    }
    .admin_n {
      background-color: white;
      text-align: center;
      border-radius: 20px;
      padding: 3px 0;
    }
  }
  .coins,
  .tags_number{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
}

