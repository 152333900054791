.Btn_Delete {
  button {
    transition: all 0.3s linear;
    
    * {
      cursor: pointer;
      
    }

    &:hover {
      scale: 1.2;
    }

    svg {
      cursor: pointer;
    }
  }
}
