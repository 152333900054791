@import "../../../partials/variables";
@import "../../../partials/mixins";

.UsersData {
  // @include page;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .UserData_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      @include title;
    }
    .search {
      width: 90%;
    }
    .result {
      padding-top: 10px;
      width: 90%;
    }
  }

  .pageScroll {
    width: 90%;
    margin: 10px;
  }
}
