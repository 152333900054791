@import "../../../../partials/variables";
@import "../../../../partials/mixins";

footer {
    height: $footer-height;
    background-color: $menu-color;
    color: white;
    // font-size: em(14px);
    @include flex-center;
}

.whiteBG{
    background-color: white;
  }