@import "../../../../partials/variables";

.InputLook_Text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  position: relative;

  .label_button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .labelBox{
      height: 33.65px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      white-space: nowrap;
    }

    .btn_edit{
      border: 3px solid white;
      padding: 5px;
      color: white;
      transition: all 0.2s linear;
  
      &:hover{
          scale: 1.1;
          right: 10px;
      }
    }
  }

  .edit{
    width: 100%;
    .intput_box {
      position: relative;
      width: 100%;
  
      &:focus-within label {
        top: -5px;
        left: 20px;
        font-size: 15px;
      }
  
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="tel"] {
        width: 100%;
        min-width: 250px;
        outline: none;
        border: none;
        color: white;
        cursor: text;
        font-size: 16px;
        background-color: transparent;
        
        border-bottom: 2px solid white;
      }
  
      label {
        cursor: text;
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        color: black;
        padding: 0 5px;
        transition: all 0.2s ease-in-out;
      }
    }

    .original_text{
      cursor: pointer;
      text-align: center;
      border-bottom: 2px solid rgba(255, 255, 255, 0);
      transition: all 0.3s linear;

      &:hover {
        border-color: white;
      }
    }
  }
}
