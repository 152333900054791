@import "../../../../partials/variables";

.UserData_listFromLast_pageScroll {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 5px 0;

  .pageSelect {
    display: flex;
    gap: 20px;

    button {
      padding: 3.5px 10px 6px 10px;
      border: 1px solid rgb(204, 204, 204);
      border-radius: 5px;
      color: black;
      font-size: 16px;

      &:hover {
        // background-color: rgb(219, 219, 219);
        background-color: lighten($dmaps-orange, 20%);
      }
    }
    .pages {
      display: flex;
      gap: 8px;

      .activePage {
        background-color: $dmaps-orange;
        color: white;
        font-weight: bold;
      }
    }

    .arrow {
      padding: 9.5px;
    }
  }
}
