// @import "../../../partials/variables";

// .ErrorMsg {
//     position: absolute;
//     // top: $header-height; /* Výchozí pozice pod headerem */
//     left: 0;
//     width: 100%;
//     background-color: rgb(242, 94, 13);
//     color: white;
//     padding: 10px;
//     z-index: 1000;
//     overflow: hidden; /* Skryje obsah při změně výšky */
//     height: 0; /* Výchozí výška */
//     transition: height 0.2s linear, top 0.1s linear;
//     border-bottom-left-radius: 30px;
//     border-bottom-right-radius: 30px;
//     font-size: 18px;
  
//     &.sticky {
//       position: fixed;
//       top: 0; /* Přilepení na vrchol */
//     }
  
//     &.visible {
//       height: 40px; /* Výška při zobrazení */
//       padding: 10px; /* Přidat padding */
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
  
//     &.hidden {
//       height: 0; /* Výška při skrytí */
//       padding: 0; /* Odebrat padding */
//     }
//   }
  

.ErrorMsg {
  position: fixed; /* Vždy pevná pozice */
  top: 0; /* Pevné umístění na vrchol */
  left: 0;
  width: 100%;
  background-color: rgb(242, 94, 13);
  color: white;
  padding: 10px;
  z-index: 1000;
  overflow: hidden;
  height: 0; /* Výchozí výška */
  transition: height 0.2s linear;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  font-size: 18px;

  &.visible {
    height: 40px; /* Výška při zobrazení */
    padding: 10px; /* Přidat padding */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.hidden {
    height: 0; /* Výška při skrytí */
    padding: 0; /* Odebrat padding */
  }
}
