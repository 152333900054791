.SearcherResult {
  width: 90%;
  .fetch_results {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .fetch_oneFetch {
      border-bottom: 1px solid black;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      gap: 30px;

      .fetch_oneFetch_box {
        display: flex;
        align-items: center;

        .fetch_oneFetch_text {
          // text-align: center;
          font-size: 25px;
          padding-bottom: 10px;
          // flex: 1;
          padding: 10px 0;
        }

        .search2_box{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: baseline;
          gap: 10px;
        }
      }

      .array1 {
        display: flex;
        // justify-content: space-between;
        border-bottom: 1px solid black;
        flex-wrap: wrap;
        padding: 0 10px 10px 10px;
        margin-bottom: 10px;
        gap: 20px;
        p {
          font-size: 20px;
        }

        .array2 {
          border-bottom: 1px solid blue;
          padding: 0 5px;
          
        }
      }
    }
  }
}
