@import "../../../../../partials/mixins";
@import "../../../../../partials/variables";

.PopUp_full {
  @include popUp_full;

  .BtnClose {
    z-index: 1;
  }

  .Tag_Users {
    @include TagIntro;
    flex-direction: column;

    .tag {
      width: 100%;

      .tag_box_data {
        border-radius: 20px;
        text-align: center;
        padding: 5px 10px;
        margin: 5px;

        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        background-color: $dmaps-darkBlue;

        .tag_name_box{
          width: 100%;
          // padding-top: 20px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          flex-wrap: wrap;
          gap: 20px;

          .delete {
            display: flex;
            justify-content: center;

            .Btn_Delete{
              
              padding-left: 10px;
              button{
                color: white;
              }
              svg{
                font-size: 30px;
              }
            }
          }
        
          .tag_name{
            .InputLook_Text{
              width: auto;
              
              input{
                text-align: center;
                font-weight: bold;
                font-size: 30px;
              }
      
              label{
                color: white;
              }
      
              .original_text{
                padding-top: 34px;
                font-size: 30px;
                color: white;
                font-weight: bold;
              }
            }
          }

          .white-space{
            min-width: 40px;
            max-width: 40px;
          }
        }

        .tagInfoData {
          border-radius: 20px;
        text-align: center;
        padding: 5px 10px;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;

          .tagInfo_box_label {
            color: rgb(136, 136, 136);
            padding: 0px 15px;
          }

          .tagInfo_box_admin {
            background-color: white;
            border-radius: 20px;
            padding: 10px;
            flex: 0.5;
            .tag_name {
              padding: 2px 7px;
              background-color: white;
              border-radius: 20px;
              white-space: nowrap;
            }
          }

          .tagInfo_box_tag {
            background-color: white;
            border-radius: 20px;
            padding: 10px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .tag {
              padding: 1px 8px;
              background-color: white;
              border-radius: 20px;
              font-size: 25px;
              white-space: nowrap;
              width: 100%;
            }
          }

          .tagInfo_box_company {
            background-color: white;
            border-radius: 20px;
            padding: 10px;
            flex: 0.5;
            .company {
              background-color: white;
              border-radius: 20px;
              padding: 2px 7px 5px 7px;
              font-size: 25px;
              border: none;
              white-space: nowrap;
              width: 100%;
              text-align: center;
            }
          }

          .tagInfo_box_dateCreate {
            background-color: white;
            border-radius: 20px;
            padding: 10px;
            flex: 0.5;
            .date {
              background-color: white;
              border-radius: 20px;
              padding: 2px 7px 3px 7px;
              // font-size: 25px;

              white-space: nowrap;
            }
          }
        }
      }
    }

    .tag_position {
      display: flex;
      width: 100%;
      margin: 10px 0;
      padding: 36px 20px 20px 20px;
      flex-wrap: wrap;
      gap: 20px;
      border-radius: 20px;
      border: 2px solid black;
      box-shadow: 0 0 10px black;

      .containter-input {
        max-width: calc(50% - 70px);
        min-width: 250px;

        label{
          background-color: transparent;
        }
      }
      .btn_editTagPosition {
        width: 100px;
      }
    }
  }
}
