.Btn_Switch_On_Another_Tag {
  display: flex;
  align-items: center;
  justify-content: center;
    .btn_Switch {
    transition: all 0.3s linear;
    background-color: white;
    border-radius: 50%;
    padding: 10px 12.5px;

    &:hover {
      transform: scale(1.2);
    }

    svg {
      font-size: 30px;
    }
  }
}
