@import "../../../../../../partials/variables";

.CompanyCEO_Details {
    .details {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s linear; // Přechod při změně výšky
      display: flex;
      justify-content: center;
  
      .details_box {
        margin-top: 5px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        border-top: none;
        position: static;
        background-color: $dmaps-lightBlue;
        border-radius: 10px;

        .item{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            background-color: white;
            padding: 10px;
            border-radius: 10px;
            
            .detail_title{
                color: $title-color;
            }
            .text{
                font-size: 20px;
            }
        }
       p{
        background-color: white;
       }
      }
    }
    
    .details_show {
      max-height: 500px; /* Nastavte dostatečně velkou hodnotu */
    }
  
    // Skryté detaily mají také přechod
    .details_hide {
      max-height: 0; /* Postupné skrytí */
      transition: max-height 0.5s linear;
    }
  }
  