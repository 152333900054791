@import "../../../../../../partials/variables";

.RespondentNew0_respondent_box{
    display: flex;
    flex-direction: column;
    gap: 30px;

    .tag_profession {
        // padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 28px;
        background-color: white;
        border: 3px solid $dmaps-darkBlue;
        padding: 28px 20px 20px 20px;
        border-radius: 20px;

    }
}