@import "../../../../../partials/variables";

.InputLine_Text {
  input {
    width: 100%;
    min-width: 250px;
    padding: 8px 10px 7px 20px;
    outline: none;
    border: none;
    border-bottom: 2px solid lighten($dmaps-orange, 30%);
    color: #0f0f0f;
    cursor: text;
    font-size: 16px;
    background-color: $main-bgColor;
    transition: all 0.3s linear;
    // background-color: lighten(#cccccc, 30%);

    &:hover {
      border-color: lighten($dmaps-orange, 15%);
      background-color: lighten(#cccccc, 15%);
    }
    &:focus {
      border-color: $dmaps-orange;
      background-color: #cccccc;
    }
  }
}
