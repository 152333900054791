@import "../../../../partials/mixins";

.Profession_Response{
    .fetch_data_one{
        @include fetch_data_one;

        .profesion_one_object{
            display: flex;
            flex-wrap: wrap;

            p{
                white-space: nowrap;
                margin-left: 10px;
                border-bottom: 2px solid rgb(156, 156, 156);
                padding: 0 3px;
            }
        }
    }
}