.Loading_Dmaps {
  img {
      display: inline-block;
      width: 100px; // Nastavte požadovanou šířku
      height: 100px; // Nastavte požadovanou výšku
      animation: rotate 5s linear infinite; // Animace na 5 sekund, nekonečná smyčka
      filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.9)); /* Stín kolem okrajů */
      // backdrop-filter: blur(10px);
      // -webkit-backdrop-filter: blur(10px); // For Safari
      border-radius: 50%;
  }
}

// Definice animace pro rotaci
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
