@import "../../partials/variables";
@import "../../partials/mixins";

.WordSet {
    // @include page;
    // padding: 2%;

    .title{
        @include title;
    }

    .search{
        width: 90%;
    }

    .result{
        width: 90%;
        // padding-top: 20px;
        flex-grow: 1;
        border: 1px solid black;
        border-radius: 20px;
        padding: 20px;
        margin: 20px;
    }

}