@import "../../../../../../partials/mixins";

.Tag_Users_Response {
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;

  // width
  .admin_box-width {
    width: 100px;
  }
  .name_email{
    flex: 1;
  }
  .word_set{
    width: 110px;
  }
  .date_create{
    width: 130px;
  }
  .actual_state{
    width: 120px;
  }
  .language{
    width: 120px;
  }


  .Tag_Users_Response_box{

    .titles {
      @include titles_line;

      .name_email{
        text-align: left;
        p{
          
          padding-left: 5px;
        }
      }
      .word_set,
      .date_create,
      .actual_state,
      .language{
        border: none;
      }
    }

    .row{
      // cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 5px;
      // padding: 5px;
      border-bottom: 1px solid rgb(204, 204, 204);

    

    &:hover {
        background-color: $oneMapSelect-hover-color;
        border-radius: 10px;
    }

    // .tagName {
    //   flex: 0.1;
    //   cursor: default;
  
    //   .tag_box {
    //     cursor: default;
  
    //     * {
    //       cursor: default;
    //     }
    //   }
    // }
    .admin_box-width {
      // width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .admin_box {
        width: 40px;
        padding: 5px;
        border-radius: 50%;
      }
      .admin_name {
        background-color: white;
        text-align: center;
        border-radius: 20px;
        padding: 3px 0;
      }
    }

    .name_email{
      @include name_email;
      flex: 1;
    }
  
    .name-hover {
      cursor: pointer;
      transition: all 0.3s linear; /* Zajisti, že přechod je i zde */
  
      &:hover {
        transform: scale(1.1);
        padding-left: 10px;
      }
    }
    .open_row {
      display: flex;
      gap: 10px;
      border-radius: 10px;
      transition: background-color 0.2s linear;
  
      * {
        cursor: pointer;
      }
  
      &:hover {
        background-color: aliceblue;
      }

      // .admin_box-width {
      //   width: 80px;
      // }
      // .name_email{
      //   flex: 1;
      // }
      .word_set,
      .date_create,
      .actual_state,
      .language{
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
    }
    
    }
  }
}
