@import "../../../../partials/variables";

.Btn_Show {
    background-color: $menu-color;
    width: 100%;
    border-radius: 20px;
    position: relative;

    border: 3px solid $dmaps-orange;
    border-top: 4px solid $dmaps-orange;

    button {
        color: white;
        font-size: 18px;
        width: 100%;
        text-align: center;
        padding: 10px;
    }
    svg {
        font-size: 30px;
        position: absolute;
        right: 16px;
        top: 8px;
    }
}