@import "../../../../../../../../partials/variables";

.Graf{
    // max-width: 700px;
    min-width: 300px;
    padding: 20px 0;

    h4{
      font-size: 20px;
      margin-left: 20px;
      background-color: aliceblue;
      width: fit-content;
      padding: 10px 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px $menu-color;
      color: $menu-color;
    }

    .meaningText {
        display: flex;
        background-color: #d8d8d8;
        padding: 10px;
        gap: 10px;
        border-radius: 20px;
        
        .meaningText_one{
          flex: 1;
          background-color: white;
          padding: 6px;
          border-radius: 10px;
          
          .graf_title{
            color: rgb(84, 84, 194);
            font-size: 20px;
            padding-bottom: 5px;
          }
          .graf_description{
            padding-bottom: 5px;
            // border-bottom: 1px solid black ;
            width: fit-content;
            margin-bottom: 5px;
          }
        }
      }
}