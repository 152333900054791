@import "../../../partials/variables";
@import "../../../partials/mixins";

.Profession{
    height: calc($navFormData-main-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .title{
        @include title;
    }
    .search{
        display: flex;
        gap: 20px;
        width: 100%;
        padding-right: 20px;
        margin-bottom: 20px;

        // button {
        //     opacity: 0; // Tlačítko je neviditelné na začátku
        //     transition: opacity 0.3s ease-in; // Přechod pro zviditelnění
        // }

        // // Třída, která bude přidána přes JS, aby se tlačítko zviditelnilo
        // .visible {
        //     opacity: 1; // Tlačítko se stane viditelným
        // }
    }
    .profession_main{
        padding: 20px 10px;
        width: 96%;
        margin: 10px;
        border-radius: 20px;
        background-color: rgb(245, 245, 245);
        box-shadow: 0 0 10px black;
        flex-grow: 1;
        overflow-y: auto;
    }
}