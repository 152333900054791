.Searcher2_MeaningOfWords{
    display: flex;
    flex: 1;
    align-items: center;
    gap: 20px;
    margin-left: 20px;

    button{
        white-space: nowrap;
    }
    
    .addBox{
        width: 100%;
        display: flex;
        gap: 20px;
    }
}