@import "../../../../../partials/variables";
@import "../../../../../partials/mixins";

$word_set-width: 110px;
$date_create-width: 130px;
$actual_status-width: 120px;

$onePiece: calc((100% - $word_set-width - $date_create-width - $actual_status-width - 50px) / 3);

.UserData_listFromLast_result {
  .UserData_listFromLast_result_box {
    
    .titles {
      @include titles_line;
      
      .tag,
      .name_email{
        width: $onePiece;
        p{
          padding-left: 10px;
        }
      }
      
      .open_row{
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        gap: 5px;

        .company{
          flex: 1;
        }
      }
    }

    .oneColorSet_info {
      display: flex;
      gap: 10px;
      padding: 5px;
      border-bottom: 1px solid rgb(204, 204, 204);

      .name_email {
        @include name_email;
        min-width: $onePiece;

        .name{
          cursor: pointer;
          transition: all 0.3s linear;

          &:hover{
            padding-left: 5px;
            transform: scale(1.1);
          }
        }
      }

      .tag{
        min-width: $onePiece;

        .tag_box{
          @include tag_box;
        }
      }
      
      .open_row{
        width: 100%;
        cursor: pointer;

        *{
          cursor: pointer;
        }

        &:hover {
          background-color: $oneMapSelect-hover-color;
          border-radius: 10px;
        }

        .company{
          display: flex;
          align-items: center;
          padding-left: 5px;
        }
      }
    }
  }

  .open_row{
    display: flex;
    gap: 10px;

    .word_set,
    .date_create,
    .actual_status{
      @include flex-center;
    }

    .company{
      flex: 1;
    }
    .word_set{
      width: $word_set-width;
    }
    .date_create{
      width: $date_create-width;
    }
    .actual_status{
      width: $actual_status-width;
    }
  }
}
