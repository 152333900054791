.PopUp_Medium{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(233, 233, 233, 0.05);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); // For Safari
    z-index: 19;
    display: flex;
    justify-content: center;
    align-items: center;

    .PopUp_window{
        min-width: 400px;
        min-height: 500px;
        width: 40%;
        height: 60%;
        border-radius: 10px;
        box-shadow: 0 0 10px black;
        background-color: rgb(248, 248, 248);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;

        h1{
            text-align: center;
            font-size: 20px;
        }

        .PopUp_window_main{
            height: 100%;
            padding-top: 20px;

            .PopUp_content{
                display: flex;
                align-items: center;
                flex-direction: column;
                height: 100%;
                padding: 20px 10px;
                background-color: rgb(255, 255, 255);
                border-radius: 20px;
                box-shadow: 0 0 5px black;
            }
        }
    }
}