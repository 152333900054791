.Password_Change{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h2{
        text-align: center;
    }
}