@import "../../../../partials/mixins";

.WordSet_Result {
  height: 100%;
  width: 100%;

  .fetch_data {
    @include fetch_data_one;
  }

  .wordsInSet{
    .btn_save{
        display: flex;
        justify-content: center;
        button{
          width: 90%; 
          background-color: rgb(133, 219, 133);
          margin-bottom: 40px;
          padding: 20px;
          transition: all 0.2s linear;

          &:hover{
            background-color: rgb(59, 218, 59);
          }
        }
    }
    .draggable-word{
        background-color: rgb(202, 202, 202);
        padding: 5px 0px;
        margin: 5px 0;
        text-align: center;
        border-radius: 30px;
    }
  }
}
