.Email_Icons_Info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 10px;
    // background-color: #b4b4b4;

    .noEmail_send{
        width: 100%;

        svg{
            width: 45px;
            height: 45px;
            color: orange;
        }
    }

    p{
        width: fit-content;
    }

    .email_details{
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        

        .email_details_dates{
            max-height: 160px;
            overflow-y: auto;
            padding: 5px 10px;
        }

        svg{
            width: 45px;
            height: 45px;
        }
    }
}