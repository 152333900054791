@import "../../../../../partials/variables";

.Btn_Delete_Confirmation{
    position: fixed;
    width: 104%;
    height: 104%;
    top: -2%;
    left: -2%;
    background-color: rgba(243, 243, 243, 0.8);
    z-index: 9998;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); // For Safar

    .Btn_Delete_Confirmation_windows{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
        border: 3px solid $dmaps-orange;
        background-color: white;;
        color: $dmaps-darkBlue;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        box-shadow: 0 0 10px black;
        padding: 20px;
        min-width: 300px;
    
        h1{
            font-size: 38px;
            color: $dmaps-orange;
            white-space: nowrap;
        }

        h2{
            font-size: 36px;
            padding: 0px 0 20px 0;
            font-weight: bold;
        }

        p{
            font-size: 18px;
        }
    
        .delete_btn_box{
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            padding-top: 20px;
    
            button{
                padding: 10px;
                background-color: $dmaps-darkBlue;
                color: white;
                border-radius: 10px;
                border: 2px solid white;
                font-size: 22px;
                width: 80px;
            }
        }
    }
}
