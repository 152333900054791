.NewTag{
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 20px;

    .btn_back {
        position: absolute;
        width: 30px;
        height: 40px;
        left: 5px;
        top: -32px;
        transition: color 0.3s linear;
        z-index: 500;
  
        &:hover {
          color: rgb(94, 94, 94);
        }
  
        svg {
          width: 100%;
          height: 100%;
        }
      }

    .tariff{
        height: 100px;
        border-radius: 20px;
        border: 2px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 5px;
        gap: 5px;

        .BTN_traiff {
            flex: 1;
            height: 90px;
            border: 1px solid black;
            font-size: 20px;
            transition: all 0.3s linear;
            color: black;

            &:hover {
                background-color: rgb(211, 234, 255);
            }
        }
        .open{
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        .number{
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }
    .tag_private{
        display: flex;
        justify-content: space-between;
    }
}