// Main page size
// height
$header-height: 60px;
$footer-height: 30px;
$margin-leftMenu: 10px;
// when change footer or header size change in MainColorRing size
$mainPage-height: calc(100vh - ($header-height + $footer-height + $margin-leftMenu));
$page-height: calc($mainPage-height + $margin-leftMenu);
$select-heigth: 36px;
$input-height: $select-heigth;

//user Data
$navDataUser-width: 280px;
$mainDataUser-width: calc(100%);

// NavFormData
$navFormData-height: 42px;
$navFormData-main-height: calc($mainPage-height - $navFormData-height);
// Media size
$media-notebook: 1300px;
$media-tablet: 800px;
$media-mobile: 550px;
// ------------------------

// Pages
$ringPage-height: calc($mainPage-height - 110px);

$color-size: 90px;

$transition: all 0.3s linear;

// Color
$dmaps-orange: rgb(242, 94, 13);
$dmaps-lightBlue: rgb(144, 221, 240);
$dmaps-darkBlue: rgb(0, 71, 119);

$menu-color: $dmaps-darkBlue;
$main-color_blue: #00aaad;
// bg
$header-color: white;
$main-bgColor: rgb(255, 255, 255);
$footer-backgroundColor: $main-color_blue;
$footer-color: rgb(206, 206, 206);

$title-color: rgb(77, 77, 77);
$a-color: #e5e5e5;
$input-color: rgb(202, 202, 202);
$border-color: rgb(204, 204, 204);
$oneMapSelect-hover-color: rgb(214, 214, 214);;

//span
$red-color: rgb(238, 51, 51);
$green-color: rgb(60, 173, 60);
$blue-color: rgb(77, 141, 194);
$purple-color: rgb(158, 55, 158);

// Components
//MainColorRing
$MaincolorRing-minPageSize: 260px;
$MainColorRing-MinRingSize: 180px;
