.NewRespondent_NewEmail_Send{
    button{
      transition: all 0.3s linear;
      background-color: white;
      border-radius: 50%;
      padding: 10px 14px;

      &:hover {
        transform: scale(1.2);    
      }

      svg{
        font-size: 30px;
      }
    }
      
  }