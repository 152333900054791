.Profession_AddProfession {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0 0 10px black;
  padding: 10px;

  .Profession_relative {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .BtnClose {
      top: 5px;
      right: 10px;
    }

    .Profession_main {
      flex-grow: 1;
      background-color: rgb(241, 241, 241);
      width: 100%;
      border-radius: 20px;
      box-shadow: 0 0 10px black;
      padding: 10px 20px;
    }

    .add_profession{
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
