@import "../../../../../../partials/variables";

.BtnPhaseNewRespondent {
  min-height: 81px;

  .error_box {
    color: red;
    text-align: center;
    padding-bottom: 10px;
    min-height: 31px;
  }
  .buttons {
    padding-top: 10px;
    display: flex;
  justify-content: space-around;


    .button_box {
        width: 72.5px;
      .btn_BtnPhaseNewRespondent {
        border: 2px solid $border-color;
        padding: 15px 20px;
        border-radius: 20px;
      }
    }
  }
}
