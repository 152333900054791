
.Profession_OneResponse{
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 10px);
    border-radius: 20px;
    box-shadow: 0 0 10px black;
    padding: 10px;

    .Profession_OneResponse_relative{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .BtnClose{
            top: 5px;
            right: 10px;
        }


        .Profession_OneResponse_main{
            flex-grow: 1;
            background-color: rgb(241, 241, 241);
            width: 100%;
            border-radius: 20px;
            box-shadow: 0 0 10px black;
            padding: 10px 20px;

            .profesion_one_object{
                display: flex;
                flex-wrap: wrap;

                p{
                    white-space: nowrap;
                }
            }
        }
    }

    
}