.Email_Icons_Info{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

    .noEmail_send{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .email_send{
        display: flex;
        justify-content: space-between;
    }

    svg{
        width: 25px;
        height: 25px;
    }

    .noEmail_send_mini{
        width: 35px;
        height: 35px;
        color: orange;
    }
}