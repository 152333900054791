@import "../../../partials/variables";

.UserDataSharedLayouts {
  display: flex;
  min-height: $mainPage-height;

  .UserDataSharedLayouts_nav {
    width: $navDataUser-width;
    position: relative; // Důležité: relativní pozice pro sticky element
    flex-shrink: 0;

    nav {
      position: sticky;
      top: 0;
      width: $navDataUser-width;
      min-height: $navFormData-main-height;
      z-index: 10;
    }
  }

  .UserDataSharedLayouts_main {
    flex: 1;
  }
}
